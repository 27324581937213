<template>
  <table style="margin-bottom: 0">
    <tr>
      <td width="36">
        <v-icon v-if="error" color="#d00">mdi-alert</v-icon>
      </td>
      <td>
        <DatePicker
          v-if="status === 'Active'"
          title="Activation date"
          :date.sync="activation"
        />
        <DatePicker
          v-if="status === 'Awaiting to be suspended'"
          title="Date to suspend"
          :date.sync="suspend"
        />
        <DatePicker
          v-if="status === 'Suspended'"
          title="Suspension date"
          :date.sync="suspended"
        />
        <DatePicker
          v-if="status === 'Awaiting for cancelation'"
          title="Date to cancel"
          :date.sync="cancel"
        />
        <DatePicker
          v-if="status === 'Canceled'"
          title="Cancelation date"
          :date.sync="canceled"
        />
      </td>
    </tr>
  </table>
</template>

<script>

import DatePicker from '@/components/inputs/DatePicker.vue'

import {
  customerController,
  errorsController
} from '@/components/customers/import/controllers'

export default {
  name: 'EditServiceLogRecordDate',

  components: {
    DatePicker
  },

  props: ['status', 'activationDate', 'suspendDate', 'suspendedDate', 'cancelDate', 'canceledDate'],

  computed: {
    error () {
      return (this.status === 'Active' && !this.activationDate) ||
        (this.status === 'Awaiting to be suspended' && !this.suspendDate) ||
        (this.status === 'Suspended' && !this.suspendedDate) ||
        (this.status === 'Awaiting for cancelation' && !this.cancelDate) ||
        (this.status === 'Canceled' && !this.canceledDate)
    },

    activation: {
      get () {
        return this.activationDate
      },
      set (value) {
        customerController.updateServiceActivationDate(value)
        this.$emit('update:activationDate', value)
      }
    },
    suspend: {
      get () {
        return this.suspendDate
      },
      set (value) {
        customerController.updateServiceSuspendDate(value)
        this.$emit('update:suspendDate', value)
      }
    },
    suspended: {
      get () {
        return this.suspendedDate
      },
      set (value) {
        customerController.updateServiceSuspendedDate(value)
        this.$emit('update:suspendedDate', value)
      }
    },
    cancel: {
      get () {
        return this.cancelDate
      },
      set (value) {
        customerController.updateServiceCancelDate(value)
        this.$emit('update:cancelDate', value)
      }
    },
    canceled: {
      get () {
        return this.canceledDate
      },
      set (value) {
        customerController.updateServiceCanceledDate(value)
        this.$emit('update:cancelDate', value)
      }
    }
  },

  methods: {
    refreshErrors () {
      this.dateError = errorsController.getError(this.index, 'activationDate') ||
        errorsController.getError(this.index, 'suspendDate') ||
        errorsController.getError(this.index, 'suspendedDate') ||
        errorsController.getError(this.index, 'cancelDate') ||
        errorsController.getError(this.index, 'canceledDate')
    }
  }
}
</script>
